<template>
  <div class="windchill-settings q-px-xl">
    <div class="windchill-settings-header flex justify-between q-pt-lg">
      <h5 class="q-mt-none q-mb-lg text-uppercase"> Configuration Settings </h5>
      <p class="text-family-condensed">
        <span class="text-weight-bold"> Windchill ID: </span>
        <template v-if="!windchillId">not created</template>
        <template v-else>{{ windchillId }}</template>
      </p>
    </div>

    <template v-if="isVariantSpecification">
      <div class="row q-gutter-sm q-mb-lg">
        <div class="col">
          <!-- Option set -->
          <mi-select
            :model-value="selectedOptionSet"
            :options="optionSets"
            disable
            label="Option set"
            option-label="name"
            option-value="id"
            options-selected-class="text-accent"
            outlined
            emit-value
            @update:model-value="setOptionSet"
          ></mi-select>
        </div>

        <div class="col">
          <!-- Target container ID -->
          <mi-select
            class="select-target-container"
            :model-value="selectedContainer"
            :display-value="selectedContainer.name"
            :options="containersCopy"
            :rules="[selectedContainerValidationRules]"
            :disable="isSavedTargetContainerValid"
            label="Target container"
            option-label="name"
            option-value="id"
            options-selected-class="text-accent"
            outlined
            emit-value
            use-input
            hide-selected
            fill-input
            @update:model-value="setContainer"
            @filter="filterContainers"
          ></mi-select>
        </div>

        <div class="col">
          <!-- Target Folder -->
          <mi-text-field
            :model-value="targetFolder"
            disable
            label="Target Folder"
            placeholder="e.g. PAC-VS"
            outlined
          ></mi-text-field>
        </div>
      </div>

      <div class="row q-gutter-sm q-mb-xs">
        <div class="col">
          <!-- Product Model -->
          <mi-text-field
            :model-value="currentConfiguration.productModel.businessName"
            disable
            label="Product Model"
            placeholder="e.g. V-Set"
            outlined
          ></mi-text-field>
        </div>

        <div class="col">
          <!-- Name -->
          <mi-text-field
            :model-value="currentConfiguration.name"
            disable
            label="Variant specification name"
            placeholder="e.g. T12345"
            outlined
          ></mi-text-field>
        </div>

        <div class="col">
          <!-- Project -->
          <mi-text-field
            :model-value="currentConfiguration.planningPeriodRange.from"
            disable
            label="Planning Period"
            placeholder="e.g. PP123456"
            outlined
          ></mi-text-field>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="row q-gutter-sm q-mb-lg">
        <div class="col">
          <!-- Option set -->
          <mi-select
            :model-value="selectedOptionSet"
            :options="optionSets"
            disable
            label="Option set"
            option-label="name"
            option-value="id"
            options-selected-class="text-accent"
            outlined
            emit-value
            @update:model-value="setOptionSet"
          ></mi-select>
        </div>

        <div class="col">
          <!-- Product Model -->
          <mi-text-field
            :model-value="currentConfiguration.productModel.businessName"
            disable
            label="Product Model"
            placeholder="e.g. V-Set"
            outlined
          ></mi-text-field>
        </div>
      </div>

      <div class="row q-gutter-sm q-mb-xs">
        <div class="col">
          <!-- Name -->
          <mi-text-field
            :model-value="currentConfiguration.name"
            disable
            label="Filter name"
            placeholder="e.g. T12345"
            outlined
          ></mi-text-field>
        </div>

        <div class="col">
          <!-- Project -->
          <mi-text-field
            :model-value="currentConfiguration.planningPeriodRange.from"
            disable
            label="Planning Period"
            placeholder="e.g. PP123456"
            outlined
          ></mi-text-field>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { exactLength, maxLength, required } from '@/utils/validators'

  export default {
    name: 'ConfigurationWindchillSettings',
    props: {
      isVariantSpecification: {
        type: Boolean,
        required: false,
        default: false
      },
      optionSets: {
        type: Array,
        required: true
      },
      containers: {
        type: Array,
        required: false,
        default: () => []
      },
      selectedOptionSetId: {
        type: String,
        required: true
      },
      selectedContainerId: {
        type: String,
        required: false,
        default: ''
      },
      targetFolder: {
        type: String,
        required: false,
        default: ''
      },
      windchillId: {
        type: String,
        required: false,
        default: undefined
      }
    },
    emits: ['update:selected-option-set-id', 'update:selected-container'],
    data: () => ({
      exactLength,
      maxLength,
      required,
      selectedOptionSet: {},
      selectedContainer: {
        description: undefined,
        id: null,
        name: 'Select or type'
      },
      containersCopy: [...(this?.containers || [])]
    }),
    computed: {
      ...mapState('pac', ['currentConfiguration']),
      isSavedTargetContainerValid() {
        const initialTargetContainerOid = this.currentConfiguration.windChillData?.targetContainerOid || ''
        return !!this.containers?.find(container => container.id === initialTargetContainerOid)
      }
    },
    created() {
      if (this.selectedOptionSetId === '') {
        this.selectedOptionSet = this?.optionSets?.find(option => option.name === 'P102322_NHMR_OptionSet_v1')
        this.setOptionSet(this.selectedOptionSet?.id || '')
      }
      else {
        this.selectedOptionSet = this.optionSets?.find(option => option.id === this.selectedOptionSetId)
      }

      if (this.selectedContainerId) {
        this.selectedContainer = this.containers?.find(container => container.id === this.selectedContainerId)
          || {
            description: undefined,
            id: null,
            name: 'Invalid Target Container'
          }
      }
    },
    methods: {
      setOptionSet(id) {
        this.selectedOptionSet = this.optionSets?.find(option => option.id === id)
        this.$emit('update:selected-option-set-id', id)
      },
      setContainer(id) {
        this.selectedContainer = this.containersCopy?.find(container => container.id === id)
        this.$emit('update:selected-container', id)
      },
      filterContainers(val, update) {
        if (val === '') {
          update(() => {
            this.containersCopy = this.containers
          })
        }
        else {
          const lowerCaseValue = val?.toLowerCase() || ''
          update(() => {
            this.containersCopy = this.containers
              .filter(container => container.name?.toLowerCase().indexOf(lowerCaseValue) > -1)
          })
        }
      },
      selectedContainerValidationRules() {
        if (!this.selectedContainer.id) return required()
        return true
      }
    }
  }
</script>
