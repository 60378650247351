<template>
  <mi-list class="configuration-details" separator>
    <!-- Imported file -->
    <mi-list-item v-if="configurationDetails.c4sFile">
      <mi-list-item-section class="text-weight-bold text-primary" avatar> Imported file </mi-list-item-section>
      <mi-list-item-section> {{ configurationDetails.c4sFile.name }} </mi-list-item-section>
    </mi-list-item>

    <!-- Configuration name -->
    <mi-list-item>
      <mi-list-item-section class="text-weight-bold text-primary" avatar> Config. Name </mi-list-item-section>
      <mi-list-item-section> {{ configurationDetails.name }} </mi-list-item-section>
    </mi-list-item>

    <!-- Project -->
    <mi-list-item>
      <mi-list-item-section class="text-weight-bold text-primary" avatar> Project </mi-list-item-section>
      <mi-list-item-section> {{ configurationDetails.project }} </mi-list-item-section>
    </mi-list-item>

    <!-- Product Model -->
    <mi-list-item>
      <mi-list-item-section class="text-weight-bold text-primary" avatar> Product Model </mi-list-item-section>
      <mi-list-item-section> {{ configurationDetails.productModel.businessName }} </mi-list-item-section>
    </mi-list-item>

    <!-- Editing Permissions -->
    <mi-list-item>
      <mi-list-item-section class="text-weight-bold text-primary" avatar> Editing Permissions </mi-list-item-section>
      <mi-list-item-section>
        {{
          configurationDetails.authorizedUsers.length > 0
            ? 'Selected users'
            : 'Everyone'
        }}
      </mi-list-item-section>
    </mi-list-item>

    <!-- Planning Period -->
    <mi-list-item>
      <mi-list-item-section class="text-weight-bold text-primary" avatar> Planning Period </mi-list-item-section>
      <mi-list-item-section> {{ configurationDetails.planningPeriodRange.from }} </mi-list-item-section>
    </mi-list-item>

    <!-- Description -->
    <mi-list-item>
      <mi-list-item-section class="text-weight-bold text-primary" avatar> Description </mi-list-item-section>
      <mi-list-item-section> {{ configurationDetails.description }} </mi-list-item-section>
    </mi-list-item>
  </mi-list>

  <!-- Stepper navigation -->
  <mi-stepper-navigation>
    <mi-btn :disable="loading" flat @click="$emit('click:back')"> Back </mi-btn>
    <mi-btn data-cy="create-btn" :loading="loading" class="q-ml-sm" @click="$emit('click:confirm')"> Create </mi-btn>
  </mi-stepper-navigation>
</template>

<script>
  export default {
    name: 'NewConfigurationDialogConfirmDetails',
    props: {
      configurationDetails: {
        type: Object,
        required: true
      },
      loading: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    emits: ['click:back', 'click:confirm']
  }
</script>

<style lang="scss" scoped>
  .configuration-details {
    margin-top: -.5rem;
    margin-bottom: -.5rem;

    ::v-deep(.mi-list-item) .q-item__section--avatar {
      min-width: 150px;
    }
  }
</style>
