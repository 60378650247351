<template>
  <div class="windchill-dialog-success">
    <div class="windchill-dialog-left">
      <span class="windchill-dialog-left--title">File Exported To Windchill</span>
      <span class="windchill-dialog-left--subtitle">Check at Windchill the filter sent.</span>
      <mi-btn class="windchill-dialog-left--btn" @click="handleDoneClick"> Done </mi-btn>
    </div>

    <div class="windchill-dialog-right">
      <!-- Header -->
      <div class="windchill-dialog-right--header">
        <mi-btn
          v-close-popup
          icon="close"
          icon-size="18px"
          icon-type
          fab
          flat
        ></mi-btn>
      </div>

      <!-- Body -->
      <div class="windchill-dialog-right--body">
        <div class="windchill-dialog-right--body--circle-1">
        </div>
        <div class="windchill-dialog-right--body--circle-2">
        </div>
        <div class="windchill-dialog-right--body--circle-3">
        </div>
        <div class="windchill-dialog-right--body--circle-full">
          <mi-icon
            class="windchill-dialog-right--body--circle-full--inner"
            name="check-circle"
            size="84px"
          ></mi-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ConfigurationWindchillSuccess',
    emits: ['done:windchill'],
    methods: {
      handleDoneClick() {
        this.$emit('done:windchill')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .windchill-dialog-success {
    height: inherit;
    display: grid;
    grid-template-rows: [row] 1fr [row];
    grid-template-columns: [col] 1.31fr [col] 1fr [col];

    .windchill-dialog-left {
      grid-column-start: 1;
      grid-column-end: 2;
      background-color: $mi-white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 56px;

      &--title {
        font-family: $mi-typography-font-family-condensed;
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 72px;
        text-transform: uppercase;
        color: $mi-red-500;
      }

      &--subtitle {
        font-family: $mi-typography-font-family;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: $mi-anthracite-800;
        margin-top: 8px;
      }

      &--btn {
        margin-top: 72px;
        width: 87px;
        height: 50px;
        font-family: $mi-typography-font-family-condensed;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
      }
    }

    .windchill-dialog-right {
      grid-column-start: 2;
      grid-column-end: 3;
      background-color: $mi-anthracite-50;
      display: flex;
      flex-direction: column;

      &--header {
        margin: 0;
        padding: 24px;
        display: flex;
        justify-content: flex-end;
        z-index: 1;
      }

      &--body {
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;

        &--circle-1 {
          box-sizing: border-box;
          width: 508px;
          height: 508px;
          left: -54px;
          opacity: .2;
          border: 2px solid $mi-white;
          border-radius: 50%;
          position: absolute;
        }

        &--circle-2 {
          box-sizing: border-box;
          width: 360px;
          height: 360px;
          left: 20px;
          top: 74px;
          opacity: .2;
          border: 2px solid $mi-white;
          border-radius: 50%;
          position: absolute;
        }

        &--circle-3 {
          box-sizing: border-box;
          width: 234px;
          height: 234px;
          left: 83px;
          top: 137px;
          opacity: .5;
          border: 2px solid $mi-white;
          border-radius: 50%;
          position: absolute;
        }

        &--circle-full {
          box-sizing: border-box;
          width: 138px;
          height: 138px;
          left: 131px;
          top: 185px;
          background: $mi-white;
          border-radius: 50%;
          position: absolute;
        }

        &--circle-full--inner {
          color: $mi-anthracite-300;
          position: absolute;
          left: 27px;
          top: 27px;
        }
      }
    }
  }

  .windchill-dialog-loading {
    background-color: $mi-anthracite-50;
    padding: 110px 17px;
    height: 700px;
    width: 924px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .windchill-dialog-loading-label {
      font-family: $mi-typography-font-family-condensed;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: .8px;
    }

    .windchill-dialog-progress-container {
      width: 240px;
      margin: 47px 0 8px;
    }
  }
</style>
