<template>
  <mi-list-item
    :ref="choice.id2"
    :choice-id="choice.id2"
    class="configuration-choice"
  >
    <mi-list-item-section class="configuration-choice__actions items-center" avatar>
      <!-- Valid/invalid icon -->
      <mi-icon
        :color="isValid ? 'positive' : 'negative'"
        :name="isValid ? 'check-circle' : 'close-circle'"
        size="16px"
      ></mi-icon>

      <!-- Lock/unlock button -->
      <mi-btn
        :color="isLocked ? 'primary' : 'secondary'"
        :disable="validation.isInProgress"
        :icon="isLocked ? 'lock-circle' : 'lock-open-circle'"
        class="configuration-choice__lock-btn text-weight-regular"
        icon-size="16px"
        dense
        fab
        icon-type
        flat
        @click="handleLockBtnClick()"
      ></mi-btn>
    </mi-list-item-section>

    <!-- Choice name & id2 -->
    <mi-list-item-section>
      <mi-list-item-label>
        <mi-checkbox
          :model-value="isSelected"
          :disable="validation.isInProgress"
          :label="`${ choice.id2 } - ${ choice.name }`"
          @update:model-value="handleCheckboxClick"
        ></mi-checkbox>
      </mi-list-item-label>
    </mi-list-item-section>
  </mi-list-item>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'

  import { CHANGED_CONFIGURATION, TOGGLE_CHOICE_LOCK, TOGGLE_CHOICE_SELECTION } from '@/store/modules/pac/mutationTypes'
  import notify from '@/utils/notify'

  const { mapMutations, mapState, mapActions } = createNamespacedHelpers('pac')

  export default {
    name: 'ConfigurationOptionsItemChoice',
    props: {
      choice: {
        type: Object,
        required: true
      },
      isLocked: {
        type: Boolean,
        required: false,
        default: false
      },
      isSelected: {
        type: Boolean,
        required: false,
        default: false
      },
      isValid: {
        type: Boolean,
        required: false,
        default: false
      },
      optionId: {
        type: String,
        required: true
      }
    },
    emits: ['child-mounted'],
    computed: {
      ...mapState(['validation'])
    },
    mounted() {
      this.$emit('child-mounted', this.$el)
    },
    methods: {
      ...mapMutations({ TOGGLE_CHOICE_LOCK, TOGGLE_CHOICE_SELECTION, CHANGED_CONFIGURATION }),
      ...mapActions(['removeSelectedChoiceFromConfiguration']),

      handleCheckboxClick() {
        this.CHANGED_CONFIGURATION(true)

        this.TOGGLE_CHOICE_SELECTION({
          choice: this.choice,
          optionId: this.optionId,
          wasSelected: this.isSelected
        })

        if (this.isSelected && this.isLocked) {
          this.TOGGLE_CHOICE_LOCK({
            choiceId: this.choice.id,
            optionId: this.optionId,
            wasLocked: this.isLocked
          })
        }

        if (this.isSelected) {
          this.removeSelectedChoiceFromConfiguration({
            optionId: this.optionId,
            choiceId: this.choice.id
          })

          notify({
            title: 'Unselected',
            content: `Invalid choice "${ this.choice.id2 || '' }" was successfully unselected`,
            type: 'positive'
          })
        }
      },
      handleLockBtnClick() {
        if (this.isSelected) {
          this.TOGGLE_CHOICE_LOCK({
            choiceId: this.choice.id,
            optionId: this.optionId,
            wasLocked: this.isLocked
          })
        }
        else {
          notify({
            title: 'Lock restriction',
            content: 'Lock is only allowed for selected choices',
            type: 'warning'
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .configuration-choice__actions.q-item__section--side.q-item__section--avatar {
    flex-direction: row;
    gap: .25rem;
    padding-right: 6px;
  }

  .configuration-choice__lock-btn.mi-btn.q-btn--fab {
    padding: .25rem;
  }
</style>
