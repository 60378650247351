<template>
  <mi-stepper
    ref="miStepper"
    v-model="currentStep"
    class="new-configuration-stepper bg-transparent q-py-sm"
    alternative-labels
    keep-alive
    no-delimiter
  >
    <!-- Select configuration type step -->
    <mi-step :name="1" :done="currentStep > 1" prefix="1" title="Select">
      <select-type
        v-model:selected-type="selectedConfigurationTypeName"
        @click:continue="$refs.miStepper?.next?.()"
      ></select-type>
    </mi-step>

    <!-- Fill configuration details step -->
    <mi-step :name="2" :done="currentStep > 2" prefix="2" title="Create">
      <create-form
        :configuration-type="selectedConfigurationTypeName"
        @click:back="$refs.miStepper?.previous?.()"
        @submit="handleCreateFormSubmit"
      ></create-form>
    </mi-step>

    <!-- Confirm configuration details step -->
    <mi-step :name="3" prefix="3" title="Confirm">
      <confirm-details
        :configuration-details="{ ...configuration, c4sFile }"
        :loading="isConfigurationCreating"
        @click:back="$refs.miStepper?.previous?.()"
        @click:confirm="handleConfigurationDetailsConfirm"
      ></confirm-details>
    </mi-step>
  </mi-stepper>
</template>

<script>
  import { createConfiguration, createC4SConfiguration } from '@/api'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import { CONFIGURATION_TYPES } from '@/constants'
  import { PAC_CREATED_CONFIG } from '@/utils/analytics/constants'

  import SelectType from './NewConfigurationStepSelectType.vue'
  import CreateForm from './NewConfigurationStepCreateForm.vue'
  import ConfirmDetails from './NewConfigurationStepConfirmDetails.vue'

  export default {
    name: 'NewConfigurationDialog',
    components: {
      SelectType,
      CreateForm,
      ConfirmDetails
    },
    emits: ['create:configuration'],
    data: () => ({
      configuration: {},
      c4sFile: null,
      currentStep: 1,
      isConfigurationCreating: false,
      selectedConfigurationTypeName: CONFIGURATION_TYPES.EMPTY.name
    }),
    methods: {
      async handleConfigurationDetailsConfirm() {
        let configurationId = ''

        this.isConfigurationCreating = true

        try {
          switch (this.selectedConfigurationTypeName) {
            case CONFIGURATION_TYPES.EMPTY.name:
              ({ id: configurationId } = await createConfiguration(this.configuration))
              break

            case CONFIGURATION_TYPES.IMPORT_FILE.name: {
              const fileContent = await this.readFileAsText(this.c4sFile)
              const blob = new Blob([fileContent], { type: 'application/json' })
              const response = await createC4SConfiguration({ file: blob, data: this.configuration })
              configurationId = response.id
              break
            }

            default:
              break
          }

          configurationId && this.$emit('create:configuration', configurationId)

          // Analytics
          recordAnalytics(
            PAC_CREATED_CONFIG,
            { type: this.selectedConfigurationTypeName }
          )
        }
        finally {
          this.isConfigurationCreating = false
        }
      },
      handleCreateFormSubmit({ configuration = {}, file = null } = {}) {
        this.configuration = configuration
        this.c4sFile = file

        this.$refs.miStepper?.next?.()
      },
      readFileAsText(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()

          reader.onload = event => {
            resolve(event.target.result)
          }

          reader.onerror = error => {
            reject(error)
          }

          reader.readAsText(file)
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .new-configuration-stepper.mi-stepper ::v-deep(.q-stepper__content) {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .new-configuration-stepper.mi-stepper ::v-deep(.q-stepper__step-inner) {
    padding: 1.5rem .5rem 0;
  }
</style>
