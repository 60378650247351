const getType = fileExtension => (fileExtension === 'excel'
  ? `application/vnd.openxmlformats-officedocument.spreadsheetml.${ fileExtension }`
  : fileExtension)

/**
 * Converts a base64-encoded string to a Blob object.
 * @param {string} base64Content The base64-encoded content to convert.
 * @param {string} fileType The type of the file, e.g. 'excel' or 'pdf'.
 * @returns {Blob} The Blob object.
 */
export function convertToBlob(base64Content, fileType = '') {
  // Create a final Blob object from the blob object received
  if (typeof base64Content === 'object') return new Blob([base64Content])

  // Decode the base64-encoded string to a binary string
  const byteCharacters = atob(base64Content)

  // Convert the binary string to a Uint8Array
  const byteArray = new Uint8Array([...byteCharacters].map(char => char.charCodeAt(0)))

  // Create a Blob object from the Uint8Array
  return new Blob([byteArray], { type: getType(fileType) })
}

export function base64Decode(base64) {
  const binaryString = atob(base64)
  const bytes = Uint8Array.from(binaryString, char => char.charCodeAt(0))
  const decoder = new TextDecoder('utf-8')
  return decoder.decode(bytes)
}
