import formatDate from '@/utils/formatDate'
import { removePrefixFromPlanningPeriod } from '@/utils/planningPeriod'

export const configurationsTableColumns = [
  {
    name: 'name',
    label: 'Name',
    align: 'left',
    field: 'name',
    showSortElement: false,
    sortDirection: 'ASC',
    style: 'width: 20%; white-space: normal; overflow-wrap: anywhere',
    sortable: true
  },
  {
    name: 'project',
    label: 'Project',
    align: 'left',
    field: 'project',
    showSortElement: false,
    sortDirection: 'ASC',
    style: 'width: 15%; white-space: normal',
    sortable: true
  },
  {
    name: 'c4sCode',
    label: 'C4S/AE',
    align: 'left',
    field: 'c4sCode',
    showSortElement: false,
    sortDirection: 'ASC',
    style: 'width: 10%; white-space: normal',
    sortable: true
  },
  {
    name: 'productModel',
    label: 'Product Model',
    align: 'left',
    field: ({ productModel = {} } = {}) => productModel.businessName || '',
    showSortElement: false,
    sortDirection: 'ASC',
    style: 'width: 10%; white-space: normal',
    sortable: true
  },
  {
    name: 'planningPeriodRange',
    label: 'Planning Period',
    align: 'left',
    field: ({ planningPeriodRange: { from } = {} } = {}) => removePrefixFromPlanningPeriod(from) || '',
    showSortElement: false,
    sortDirection: 'ASC',
    style: 'width: 10%; white-space: normal',
    sortable: true
  },
  {
    name: 'creator',
    label: 'User',
    align: 'left',
    field: 'creator',
    showSortElement: false,
    sortDirection: 'ASC',
    style: 'width: 5%; white-space: normal',
    sortable: true
  },
  {
    name: 'createdDate',
    label: 'Created',
    align: 'left',
    field: ({ createdDate } = {}) => formatDate(createdDate) || '',
    showSortElement: false,
    sortDirection: 'ASC',
    style: 'width: 10%; white-space: normal',
    sortable: true
  },
  {
    name: 'modifiedDate',
    label: 'Modified',
    align: 'left',
    field: ({ modifiedDate } = {}) => formatDate(modifiedDate) || '',
    showSortElement: true,
    sortDirection: 'DESC',
    style: 'width: 10%; white-space: normal',
    sortable: true
  },
  {
    name: 'description',
    label: 'Description',
    align: 'left',
    field: 'description',
    showSortElement: false,
    sortDirection: 'ASC',
    style: 'width: 20%; white-space: normal',
    sortable: true
  }
]
