<template>
  <div class="row justify-between items-center">
    <div class="col-3 col-xl-2">
      <!-- Search field -->
      <mi-text-field
        :model-value="configurationsSearchString"
        bg-color="white"
        debounce="300"
        placeholder="Search"
        clearable
        hide-bottom-space
        outlined
        @update:model-value="SET_CONFIGURATIONS_SEARCH_STRING"
      >
        <template #prepend>
          <mi-icon name="search"></mi-icon>
        </template>
      </mi-text-field>
    </div>

    <div class="col-auto">
      <!-- New configuration dialog trigger -->
      <mi-btn
        class="q-ml-sm"
        color="accent"
        icon-right="plus-circle"
        icon-right-size="14px"
        @click="isNewConfigurationDialogShown = true"
      >
        New Configuration
      </mi-btn>
    </div>

    <!-- New configuration dialog -->
    <mi-dialog v-model="isNewConfigurationDialogShown" class="new-configuration-dialog" persistent>
      <!-- Title -->
      <h5 class="q-mt-none q-mb-sm"> New configuration </h5>

      <!-- Subtitle -->
      <p class="q-mb-none"> Please, follow the steps in order to create configuration </p>

      <!-- Configuration stepper -->
      <new-configuration-stepper
        v-if="isNewConfigurationDialogShown"
        @create:configuration="handleConfigurationCreatedEvent"
      ></new-configuration-stepper>
    </mi-dialog>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import { PAC_CONFIGURATION_ROUTE } from '@/router/routeNames'
  import { SET_CONFIGURATIONS_SEARCH_STRING } from '@/store/modules/pac/mutationTypes'

  import NewConfigurationStepper from '@/components/pac/new-configuration-stepper/NewConfigurationStepper.vue'

  const { mapState, mapMutations } = createNamespacedHelpers('pac')

  export default {
    name: 'ConfigurationsHeader',
    components: {
      NewConfigurationStepper
    },
    data: () => ({
      isNewConfigurationDialogShown: false
    }),
    computed: {
      ...mapState(['configurationsSearchString'])
    },
    methods: {
      ...mapMutations({ SET_CONFIGURATIONS_SEARCH_STRING }),

      handleConfigurationCreatedEvent(id = '') {
        this.isNewConfigurationDialogShown = false

        id && this.$router.push({
          name: PAC_CONFIGURATION_ROUTE.name,
          params: { id }
        })
      }
    }
  }
</script>

<style lang="scss">
  .new-configuration-dialog {
    .q-dialog__inner > .mi-card {
      width: 924px;
      max-width: 924px;
      min-width: 580px;
    }
  }

  @media (max-width: $mi-responsive-large-width) {
    .new-configuration-dialog {
      .q-dialog__inner > .mi-card {
        width: 683px;
        max-width: 683px;
      }
    }
  }

  @media (max-width: $mi-responsive-medium-width) {
    .new-configuration-dialog {
      .q-dialog__inner > .mi-card {
        width: 477px;
        max-width: 477px;
      }
    }
  }
</style>
