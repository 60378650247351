<template>
  <section class="option-changed-notification__wrapper row">
    <div class="option-changed-notification__wrapper--content">
      <img
        class="option-changed-notification__wrapper--content__icon"
        src="@/assets/images/pac/update_feedback.svg"
        alt="options has been changed"
        width="16"
        height="16"
      />
      <span class="option-changed-notification__wrapper--content__text">
        Some changes have been made.
        Remember to validate again to check the most accurate configuration result.
      </span>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'ConfigurationOptionsChangedNotification'
  }
</script>

<style lang="scss" scoped>
  .option-changed-notification {
    &__wrapper {
      margin: 0 24px;

      &--content {
        width: 100vw;
        display: flex;
        height: 32px;
        padding: 10px;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        border: 1px solid var(--color-blue-800, #3165a0);
        background: var(--color-blue-50, #e5f2f9);

        &__icon {
          color: var(--color-blue-800, #3165a0);
          margin-left: .5vw;
        }

        &__icon path {
          fill: #3165a0;
        }

        &__text {
          color: var(--color-blue-800, #3165a0);
          font-family: $mi-typography-font-family-condensed;
          font-size: 11px;
          font-style: normal;
          font-weight: 700;
          line-height: 130%;
        }
      }
    }
  }
</style>
