<template>
  <!-- Header -->
  <div class="windchill-dialog-header">
    <mi-btn
      v-close-popup
      icon="close"
      icon-type
      icon-size="18px"
      fab
      flat
    ></mi-btn>
  </div>

  <div class="windchill-dialog-loading">
    <mi-icon name="file-copy" color="accent" size="72px"></mi-icon>
    <div class="windchill-dialog-progress-container">
      <mi-linear-progress
        ref="miLinearProgress"
        rounded
        size="4px"
        track-color="white"
        color="primary"
        :max-duration="maxDuration"
        :animation-speed="500"
      ></mi-linear-progress>
    </div>
    <span class="windchill-dialog-loading-label">{{ loadingLabel }}</span>
  </div>
</template>

<script>
  export default {
    name: 'ConfigurationWindchillStatus',
    props: {
      loadingLabel: {
        type: String,
        required: true
      },
      maxDuration: {
        type: Number,
        required: false,
        default: undefined
      }
    },
    mounted() {
      this.$refs.miLinearProgress?.start?.()
    },
    methods: {
      finishLoading() {
        this.$refs.miLinearProgress?.stop?.()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .windchill-dialog-header {
    background-color: $mi-anthracite-50;
    height: 111px;
    width: 924px;
    margin: 0;
    padding: 24px;
    display: flex;
    justify-content: flex-end;
    position: fixed;
    z-index: 1;
  }

  .windchill-dialog-loading {
    background-color: $mi-anthracite-50;
    padding: 110px 17px;
    height: 700px;
    width: 924px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .windchill-dialog-loading-label {
      font-family: $mi-typography-font-family-condensed;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: .8px;
    }

    .windchill-dialog-progress-container {
      width: 240px;
      margin: 47px 0 8px;
    }
  }
</style>
