<template>
  <mi-tabs
    :model-value="modelValue"
    class="configuration-types-tabs"
    dense
    @update:model-value="updateActiveValidationErrorType"
  >
    <mi-tab
      v-for="({ key, value }) in PAC_CONFIGS_TABS"
      :key="key"
      :name="value"
      :label="value"
      :badge="key === PAC_CONFIGS_TABS.ALL.key ? getOptionsSize : getInvalidOptionsSize"
    ></mi-tab>
  </mi-tabs>
</template>

<script>
  import { PAC_CONFIGS_TABS } from '@/constants'
  import { createNamespacedHelpers } from 'vuex'

  const { mapGetters } = createNamespacedHelpers('pac')
  export default {
    name: 'ConfigurationOptionsTabs',
    props: {
      errorsTotalSizes: {
        type: Object,
        required: true
      },
      modelValue: {
        type: String,
        required: true
      }
    },
    emits: ['update:model-value'],
    data: () => ({ PAC_CONFIGS_TABS }),
    computed: {
      ...mapGetters(['getOptionsSize', 'getInvalidOptionsSize'])
    },
    methods: {
      emitUpdateModelValue(modelValue = '') {
        this.$emit('update:model-value', modelValue)
      },
      updateActiveValidationErrorType(selectedType = '') {
        this.emitUpdateModelValue(selectedType)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .configuration-types-tabs ::v-deep(.mi-tab) {
    padding: 0 1rem;
  }

  .configuration-types-tabs ::v-deep(.q-tab__label) {
    font-size: .875rem;
  }
</style>
