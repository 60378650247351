<template>
  <div class="q-px-xl">
    <div class="windchill-settings-header flex justify-between q-pt-lg">
      <h5 class="q-mt-none q-mb-lg text-uppercase"> filtering criterias </h5>
    </div>
    <mi-expansion-item
      v-model="isStructureFilteringExpanded"
      expand-icon-toggle
      class="filtering-criteria-item"
      no-expand-icon
    >
      <!-- Header -->
      <template #header>
        <h6 class="text-uppercase q-mt-none q-mb-lg"> structure objects </h6>
        <mi-icon class="q-ml-xs text-left" name="info-circle" size="1rem">
          <mi-tooltip :offset="[5, 5]" anchor="bottom middle" self="top middle">
            Effectivity used to filter the green, blue and grey objects in Windchill.<br />
            The recommended setting was preselected based on the product model set for this configuration.
          </mi-tooltip>
        </mi-icon>
        <div class="q-pl-md filtering-text q-pt-sm col-8">
          <div class="text-right">
            <span v-if="structureButtonState === 'Change'">
              {{ selectedStructurePrimaryCriteriaWithLabel }} </span>
            <br />
            <span v-if="isExpandedNewestDesign" class="text-left"> With the newest design </span>
          </div>
        </div>
        <div class="expand-button text-right col">
          <mi-btn flat @click="expandStructureFilterCriteria">{{ structureButtonState }}</mi-btn>
        </div>
      </template>

      <!-- Content -->
      <div class="q-ml-sm">
        <div>
          <span class="filtering-text-bold q-ml-sm">Primary criterion - Effectivity</span>
          <br />
          <span class="filtering-text q-ml-sm">
            Select what kind of effectivity should be used in Windchill as primary criterion</span>
          <div class="q-mt-sm">
            <q-option-group
              :model-value="selectedStructurePrimaryCriteria"
              type="radio"
              :options="configurationStructureOptionsRadioBoxStruct"
              color="accent"
              class="radio-filtering-text"
              @update:model-value="applyStructurePrimaryCriteriaSelection"
            ></q-option-group>
          </div>
        </div>
        <div class="q-mt-lg filtering-criteria-item">
          <div class="q-mt-lg">
            <span class="filtering-text-bold q-ml-md">Secondary criterion - Effectivity</span>
          </div>
          <div class="q-mt-md q-mb-md q-ml-md">
            <mi-switch
              v-model="selectedStructureSecondaryCriteria"
              label="Show newest versions as result"
              size="lg"
              color="primary"
              @click="applySecondaryCriteriaSelection"
            >
            </mi-switch>
          </div>
        </div>
      </div>
    </mi-expansion-item>
    <mi-expansion-item
      v-model="isYellowFilteringExpanded"
      expand-icon-toggle
      class="filtering-criteria-item"
      no-expand-icon
    >
      <!-- Header -->
      <template #header>
        <h6 class="text-uppercase q-mt-none q-mb-lg"> Yellow objects </h6>
        <mi-icon class="q-ml-xs text-left" name="info-circle" size="1rem">
          <mi-tooltip :offset="[5, 5]" anchor="bottom middle" self="top middle">
            Effectivity used to filter the yellow objects in Windchill. The recommended setting was preselected
            based on the product model set for this configuration.
          </mi-tooltip>
        </mi-icon>
        <div class="q-pl-md filtering-text q-pt-sm col-8">
          <div class="text-right">
            <span v-if="yellowButtonState === 'Change'">
              {{ selectedYellowPrimaryCriteriaWithLabel }} </span>
          </div>
        </div>
        <div class="absolute-right expand-button col">
          <mi-btn flat @click="expandYellowFilterCriteria">{{ yellowButtonState }}</mi-btn>
        </div>
      </template>

      <!-- Content -->
      <div class="q-ml-sm">
        <div>
          <span class="filtering-text-bold q-ml-md">Primary criterion - Effectivity</span>
          <br />
          <span class="filtering-text q-ml-md">
            Select what kind of effectivity should be used in Windchill as primary criterion</span>
          <div class="q-mt-md">
            <q-option-group
              :model-value="selectedYellowPrimaryCriteria"
              type="radio"
              :options="configurationYellowOptionsRadioBoxStruct"
              color="accent"
              class="radio-filtering-text q-mt-sm"
              @update:model-value="applyYellowPrimaryCriteriaSelection"
            ></q-option-group>
          </div>
        </div>
      </div>
    </mi-expansion-item>
  </div>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'

  const { mapGetters } = createNamespacedHelpers('pac')

  export default {
    name: 'ConfigurationWindchillFilteringCriterias',
    props: {
      productModel: {
        type: String,
        required: true
      },
      structurePrimaryCriteria: {
        type: String,
        required: true
      },
      structureSecondaryCriteria: {
        type: Boolean,
        required: true
      },
      yellowPrimaryCriteria: {
        type: String,
        required: true
      }
    },
    emits: ['update:structurePrimaryCriteria', 'update:structureSecondaryCriteria',
            'update:yellowPrimaryCriteria'],
    data: () => ({
      isStructureFilteringExpanded: false,
      structureButtonState: 'Change',
      isYellowFilteringExpanded: false,
      yellowButtonState: 'Change',
      selectedStructurePrimaryCriteria: '',
      selectedStructureSecondaryCriteria: false,
      selectedYellowPrimaryCriteria: '',
      primaryStructureCriteriaRecommended: '',
      primaryYellowCriteriaRecommended: '',
      selectedStructurePrimaryCriteriaWithLabel: '',
      selectedYellowPrimaryCriteriaWithLabel: ''
    }),
    computed: {
      ...mapGetters(['configStructureOptionsPerProductModel', 'configSpecificationFilterOptionsPerProductModel']),
      configurationStructureOptionsRadioBoxStruct() {
        const structureOptions = this.configStructureOptionsPerProductModel(this.productModel)
          ?.map(struct => this.getRadioButtonLayout(struct))
        structureOptions?.push({ label: 'Ignore primary criterion', value: 'ignore' })
        return structureOptions
      },
      configurationYellowOptionsRadioBoxStruct() {
        return this.configSpecificationFilterOptionsPerProductModel(this.productModel)
          ?.map(struct => this.getRadioButtonLayout(struct))
      },
      isExpandedNewestDesign() {
        return this.selectedStructureSecondaryCriteria === true && this.structureButtonState === 'Change'
      }
    },
    created() {
      this.primaryStructureCriteriaRecommended = this.structurePrimaryCriteria
      this.selectedStructureSecondaryCriteria = this.structureSecondaryCriteria
      this.primaryYellowCriteriaRecommended = this.yellowPrimaryCriteria
      this.selectedStructurePrimaryCriteria = this.structurePrimaryCriteria
      this.selectedYellowPrimaryCriteria = this.yellowPrimaryCriteria
      this.getStructurePrimaryCriteriaWithLabel()
      this.getYellowPrimaryCriteriaWithLabel()
    },
    methods: {
      getYellowPrimaryCriteriaWithLabel() {
        this.selectedYellowPrimaryCriteriaWithLabel = this.selectedYellowPrimaryCriteria
        if (this.selectedYellowPrimaryCriteria === this.primaryYellowCriteriaRecommended) {
          this.selectedYellowPrimaryCriteriaWithLabel += ' (Recommended)'
        }
      },
      getStructurePrimaryCriteriaWithLabel() {
        this.selectedStructurePrimaryCriteriaWithLabel = this.selectedStructurePrimaryCriteria
        if (this.selectedStructurePrimaryCriteria === this.primaryStructureCriteriaRecommended) {
          this.selectedStructurePrimaryCriteriaWithLabel += ' (Recommended)'
        }
      },
      expandStructureFilterCriteria() {
        this.isStructureFilteringExpanded = !this.isStructureFilteringExpanded
        this.structureButtonState = this.isStructureFilteringExpanded ? 'Hide' : 'Change'
      },
      expandYellowFilterCriteria() {
        this.isYellowFilteringExpanded = !this.isYellowFilteringExpanded
        this.yellowButtonState = this.isYellowFilteringExpanded ? 'Hide' : 'Change'
      },
      applyStructurePrimaryCriteriaSelection(value) {
        this.selectedStructurePrimaryCriteria = value
        if (this.selectedStructurePrimaryCriteria === 'ignore') {
          this.selectedStructureSecondaryCriteria = true
        }
        this.getStructurePrimaryCriteriaWithLabel()
        this.$emit('update:structurePrimaryCriteria', this.selectedStructurePrimaryCriteria)
      },
      applySecondaryCriteriaSelection() {
        if (this.selectedStructurePrimaryCriteria === 'ignore' && this.selectedStructureSecondaryCriteria === false) {
          this.selectedStructurePrimaryCriteria = this.primaryStructureCriteriaRecommended
          this.getStructurePrimaryCriteriaWithLabel()
          this.$emit('update:structurePrimaryCriteria', this.selectedStructurePrimaryCriteria)
        }
        this.getYellowPrimaryCriteriaWithLabel()
        this.$emit('update:structureSecondaryCriteria', this.selectedStructureSecondaryCriteria)
      },
      applyYellowPrimaryCriteriaSelection(value) {
        this.selectedYellowPrimaryCriteria = value
        this.getYellowPrimaryCriteriaWithLabel()
        this.$emit('update:yellowPrimaryCriteria', this.selectedYellowPrimaryCriteria)
      },
      getRadioButtonLayout(struct) {
        let newLabel = struct.name
        if (struct.recommended === true) {
          newLabel += (' (Recommended)')
        }
        return { label: newLabel, value: struct.name }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .filtering-criteria-item {
    border-top: 1px solid $mi-anthracite-50;
  }

  .expand-button {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    background-color: $mi-white;
  }

  .filtering-text-bold {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    font-family: $mi-typography-font-family-condensed;
  }

  .filtering-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: $mi-anthracite-500;
  }

  .radio-filtering-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
  }

  .filtering-criteria-item ::v-deep(.mi-expansion-item__header) {
    padding-right: 0;
    align-items: baseline;
    margin-left: 0;
  }
</style>
