<template>
  <mi-form
    class="windchill-variant-specification"
    @submit.prevent="exportToWindchill"
  >
    <div class="windchill-variant-specification--container">
      <!-- Configuration Settings  -->
      <configuration-windchill-settings
        v-model:selected-option-set-id="selectedOptionSetId"
        v-model:selected-container-id="selectedContainerId"
        is-variant-specification
        :option-sets="optionSets"
        :containers="containers"
        :target-folder="getTargetFolder"
        :windchill-id="windchillId"
        @update:selected-container="handleSelectedContainer"
      ></configuration-windchill-settings>

      <!-- Filtering Criterias  -->
      <configuration-windchill-filtering-criterias
        v-model:structure-primary-criteria="filterStructurePrimaryCriteria"
        v-model:structure-secondary-criteria="filterStructureSecondaryCriteria"
        v-model:yellow-primary-criteria="filterYellowPrimaryCriteria"
        :product-model="productModel"
      >
      </configuration-windchill-filtering-criterias>
    </div>

    <div class="windchill-variant-specification--actions">
      <mi-btn flat @click="handleBackClick"> Back </mi-btn>
      <mi-btn type="submit">
        <span v-if="hasWindchillData"> Update </span>
        <span v-else> Export </span>
      </mi-btn>
    </div>
  </mi-form>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import {
    createWindchillVariantSpecification,
    getWindchillVariantSpecification,
    updateWindchillVariantSpecification
  } from '@/api'
  import ConfigurationWindchillSettings
    from '@/components/pac/configuration/windchill-interface/ConfigurationWindchillSettings.vue'
  import ConfigurationWindchillFilteringCriterias
    from '@/components/pac/configuration/windchill-interface/ConfigurationWindchillFilteringCriterias.vue'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import { PAC_WINDCHILL_VARIANT_EXPORT } from '@/utils/analytics/constants'
  import { poll } from '@/api/polling'
  import notify from '@/utils/notify'

  const { mapActions, mapState, mapGetters } = createNamespacedHelpers('pac')

  export default {
    name: 'ConfigurationVariantSpecification',
    components: { ConfigurationWindchillSettings, ConfigurationWindchillFilteringCriterias },
    props: {
      configSpecs: {
        type: Array,
        required: true
      },
      containers: {
        type: Array,
        required: true
      },
      optionSets: {
        type: Array,
        required: true
      },
      selectedContainer: {
        type: String,
        required: false,
        default: ''
      }
    },
    emits: [
      'back:windchill',
      'status:windchill-export',
      'async:clear-poll',
      'update:selected-container'
    ],
    data: () => ({
      filterStructurePrimaryCriteria: '',
      filterStructureSecondaryCriteria: false,
      filterYellowPrimaryCriteria: '',
      productModel: '',
      selectedOptionSetId: '',
      selectedContainerId: ''
    }),
    computed: {
      ...mapState(['currentConfiguration', 'selectedChoices']),
      ...mapGetters(['configStructureOptionsPerProductModel', 'configSpecificationFilterOptionsPerProductModel']),
      windchillId() {
        return this?.currentConfiguration?.windChillData?.variantSpecNumber
      },
      hasWindchillData() {
        return !!this.currentConfiguration.windChillData && this.currentConfiguration.windChillData.variantSpecNumber
      },
      getTargetFolder() {
        return this.configSpecs
          .find(config => config.pmBusinessName === this.currentConfiguration.productModel.businessName).targetFolder
      }
    },
    watch: {
      selectedContainer(current) {
        this.selectedContainerId = current
      }
    },
    created() {
      this.selectedContainerId = this.currentConfiguration.windChillData?.targetContainerOid || this.selectedContainer
      this.productModel = this.currentConfiguration.productModel.businessName
      this.filterStructurePrimaryCriteria = this.structureOptionsRecommendedPerProductModel(this.productModel)
      this.filterYellowPrimaryCriteria = this.configSpecFilterOptionsRecommendedPerProductModel(this.productModel)
    },
    methods: {
      ...mapActions(['loadConfiguration', 'saveConfiguration']),
      handleSelectedContainer(id) {
        this.$emit('update:selected-container', id)
      },
      structureOptionsRecommendedPerProductModel(productModel) {
        return this.configStructureOptionsPerProductModel(productModel)
          .find(structureOption => structureOption.recommended === true).name
      },
      configSpecFilterOptionsRecommendedPerProductModel(productModel) {
        return this.configSpecificationFilterOptionsPerProductModel(productModel)
          .find(structureOption => structureOption.recommended === true).name
      },
      handleBackClick() {
        this.$emit('back:windchill')
      },
      async exportToWindchill() {
        const request = this.buildVariantSpecRequest()

        // save configuration
        try {
          this.$emit('status:windchill-export', { isSavingConfiguration: true })

          await this.saveConfiguration(this.currentConfiguration)
        }
        catch {
          this.$emit('status:windchill-export', { isSavingConfiguration: false })
          return
        }

        // export to windchill
        try {
          this.$emit('status:windchill-export', { isSavingConfiguration: false, isExportingToWindchill: true })

          if (this.hasWindchillData) {
            await this.getUnusedUpdateWindchill(request)
          }
          else {
            await this.getUnusedCreateWindchill(request)
          }

          this.loadConfiguration(this.currentConfiguration.id)
        }
        catch {
          this.$emit('status:windchill-export', { isExportingToWindchill: false })
          return
        }

        // export to windchill successful
        this.$emit('status:windchill-export', { isExportingToWindchill: false, isExportSuccessful: true })

        // Analytics
        recordAnalytics(PAC_WINDCHILL_VARIANT_EXPORT)
      },
      async getUnusedUpdateWindchill(request) {
        const { executePoll, clearPoll } = poll()
        this.$emit('async:clear-poll', { clearPoll })

        try {
          await executePoll({
            correlationIdEndpoint: {
              getCorrelationId: updateWindchillVariantSpecification,
              params: request
            },
            asyncEndpoint: getWindchillVariantSpecification
          })
        }
        catch (e) {
          notify({
            title: `HTTP Error ${ e.status || '' }`,
            content: e.message || 'Fetch PAC Windchill Variant Specification',
            type: 'negative'
          })
          throw e
        }
      },
      async getUnusedCreateWindchill(request) {
        const { executePoll, clearPoll } = poll()
        this.$emit('async:clear-poll', { clearPoll })

        try {
          await executePoll({
            correlationIdEndpoint: {
              getCorrelationId: createWindchillVariantSpecification,
              params: request
            },
            asyncEndpoint: getWindchillVariantSpecification
          })
        }
        catch (e) {
          notify({
            title: `HTTP Error ${ e.status || '' }`,
            content: e.message || 'Fetch PAC Windchill Variant Specification',
            type: 'negative'
          })
          throw e
        }
      },
      buildVariantSpecRequest() {
        const optionsAndChoices = Array.from(this.selectedChoices).map(entry => ({
          optionId: entry[0],
          choiceNumbers: entry[1].map(choice => choice.id2)
        }))
        return {
          configurationSpecificationStructure: {
            isLatestDesign: this.filterStructureSecondaryCriteria,
            unitEffectivity: [{
              context: this.filterStructurePrimaryCriteria,
              effectivityValue: this.currentConfiguration.planningPeriodRange.from.substring(2)
            }]
          },
          configurationSpecificationOptionFilter: {
            unitEffectivity: [{
              context: this.filterYellowPrimaryCriteria,
              effectivityValue: this.currentConfiguration.planningPeriodRange.from.substring(2)
            }]
          },
          optionSetOid: this.selectedOptionSetId,
          targetContainerOid: this.selectedContainerId,
          variantSpecName: this.currentConfiguration.name,
          variantSpecDescription: this.currentConfiguration.description || this.currentConfiguration.name,
          targetFolder: this.getTargetFolder,
          filterMode: 'Alternate',
          configurationId: this.currentConfiguration.id,
          optionsAndChoices
        }
      }
    }
  }
</script>

<style lang="scss">

  .windchill-variant-specification {
    margin-top: 110px;
    height: 590px;
    display: flex;
    flex-direction: column;

    &--container {
      height: 506px;
      overflow-y: scroll;
      -ms-overflow-style: none;  // IE and Edge
      scrollbar-width: none;  // Firefox

      // Chrome, Safari and Opera
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &--actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      padding: 0 24px 24px;
      gap: 16px;
      width: 100%;
      height: 84px;
      box-shadow: 0 8px 16px rgba(0, 0, 0, .24);
    }
  }

</style>
