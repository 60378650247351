<template>
  <page-layout-with-top-bar>
    <!-- Configuration(s) header -->
    <template #top-bar>
      <router-view name="header"></router-view>
    </template>

    <router-view></router-view>
  </page-layout-with-top-bar>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import PageLayoutWithTopBar from '@/layouts/PageLayoutWithTopBar.vue'

  const { mapActions } = createNamespacedHelpers('product-model')

  export default {
    name: 'Pac',
    components: { PageLayoutWithTopBar },
    mounted() {
      this.$nextTick(() => {
        this.getProductModelsList()
      })
    },
    methods: {
      ...mapActions(['getProductModelsList'])
    }
  }
</script>
