<template>
  <mi-select
    ref="miSelect"
    :model-value="appliedFilters"
    :menu-offset="[0, 4]"
    :options="filterTypes"
    menu-anchor="bottom right"
    menu-self="top right"
    option-label="title"
    option-value="name"
    options-selected-class="text-accent"
    borderless
    dense
    emit-value
    hide-dropdown-icon
    multiple
    @update:model-value="handleActiveFilterUpdate"
  >
    <!-- Selected slot -->
    <template #selected>
      <mi-btn
        :color="appliedFilters.length ? 'accent' : 'primary'"
        :flat="!appliedFilters.length"
        class="full-height"
        icon-right="filter"
        icon-right-size="12px"
        dense
      >
        Filter
      </mi-btn>
    </template>

    <!-- Option slot -->
    <template #option="{ itemProps, opt }">
      <mi-list-item v-bind="itemProps" @click="handleOptionClick(opt.name)">
        <mi-list-item-section> {{ opt.title }} </mi-list-item-section>
      </mi-list-item>
    </template>
  </mi-select>
</template>

<script>
  export default {
    name: 'ConfigurationOptionsFilter',
    props: {
      filterTypes: {
        type: Array,
        required: true
      },
      modelValue: {
        type: String,
        required: true
      }
    },
    emits: ['update:model-value'],
    data: () => ({
      appliedFilters: []
    }),
    watch: {
      modelValue: {
        immediate: true,
        handler(newValue) {
          if (!newValue?.length) {
            this.appliedFilters = []
          }
        }
      }
    },
    methods: {
      handleActiveFilterUpdate(value = []) {
        this.appliedFilters = value?.slice(-1) || []
        this.$refs.miSelect?.hidePopup?.()
      },
      handleOptionClick(filterName = '') {
        const newValue = this.appliedFilters.length ? filterName : ''

        this.$emit('update:model-value', newValue)
      }
    }
  }
</script>
