<template>
  <!-- Container -->
  <div class="windchill-dialog-container">
    <p class="windchill-dialog-container--paragraph text-family-condensed q-mt-xl">
      Select how you want to use the configuration in Windchill
    </p>

    <!-- Filter -->
    <mi-btn class="windchill-dialog-btn q-mb-lg" flat @click="openFilter">
      <mi-icon class="windchill-dialog-btn--icon q-ma-md" name="filter" color="white" size="16px"></mi-icon>
      <div class="windchill-dialog-btn--text">
        <div class="text-uppercase"> Saved Filter </div>
        <div class="windchill-dialog-btn--subtitle">
          <div> Filter for personal use and non-official configurations or investigations. </div>
          <div> It can be shared with others if needed. </div>
        </div>
      </div>
    </mi-btn>

    <!-- Variant Specification -->
    <mi-btn class="windchill-dialog-btn q-mb-lg" flat @click="openVariantSpecification">
      <img
        class="q-ma-md"
        src="@/assets/images/variant-specification.svg"
        alt="Variant Specification image"
      />
      <div class="windchill-dialog-btn--text">
        <div class="text-uppercase"> Variant Specification </div>
        <div class="windchill-dialog-btn--subtitle">
          <div> Filter object for official vehicle configurations. </div>
          <div> It is shared with everyone and can work with versions and iterations. </div>
        </div>
      </div>
    </mi-btn>

    <!-- Download manual export -->
    <mi-btn
      class="windchill-dialog-download-btn"
      flat
      :loading="isConfigurationExporting"
      @click="exportWindchillFile"
    >
      Download json
    </mi-btn>
  </div>
</template>

<script>
  import { exportWindchillConfiguration } from '@/api'
  import { exportFile } from 'quasar'
  import { createNamespacedHelpers } from 'vuex'
  import notify from '@/utils/notify'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'

  import { PAC_WINDCHILL_CONFIGURATION_EXPORTED } from '@/utils/analytics/constants'

  const { mapGetters, mapState, mapActions } = createNamespacedHelpers('pac')

  export default {
    name: 'ConfigurationWindchillFirstStep',
    emits: ['open:variant-specification', 'open:filter', 'export:done'],
    data() {
      return {
        isConfigurationExporting: false
      }
    },
    computed: {
      ...mapGetters(['configurationProductModelId']),
      ...mapState(['currentConfiguration'])
    },
    async created() {
      await this.getConfigSpecs()
    },
    methods: {
      ...mapActions(['getConfigSpecs']),
      async exportWindchillFile() {
        this.isConfigurationExporting = true

        try {
          const file = await exportWindchillConfiguration({
            ...this.currentConfiguration,
            productModel: {
              ...this.currentConfiguration.productModel,
              id: this.configurationProductModelId
            }
          })

          exportFile('Windchill_Configuration.json', JSON.stringify(file), 'application/json')

          this.$emit('export:done')

          notify({
            title: 'File downloaded',
            content: 'Check your download folder to use it',
            type: 'positive'
          })

          // Analytics
          recordAnalytics(PAC_WINDCHILL_CONFIGURATION_EXPORTED)
        }
        finally {
          this.isConfigurationExporting = false
        }
      },
      openVariantSpecification() {
        // Emit something
        this.$emit('open:variant-specification')
      },
      openFilter() {
        // Emit something here
        this.$emit('open:filter')
      }
    }
  }
</script>

<style lang="scss">
  .windchill-dialog-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 68px;
    height: 589px;
    min-height: 589px;
    max-height: 589px;
    font-weight: 400;
    margin-top: 110px;

    &--paragraph {
      font-size: 1.5rem;
      color: $mi-anthracite-800;
      margin-bottom: 70px;
    }
  }

  .windchill-dialog-btn {
    display: flex;
    align-items: flex-start;
    width: 576px;
    height: 100px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, .24);

    &--icon {
      background-color: $mi-red-600;
      height: 40px;
      width: 40px;
      border-radius: 12px;
    }

    &--text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }

    &--subtitle {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: $mi-anthracite-500;
      font-weight: 400;
      font-size: .875rem;
      letter-spacing: 0;
      margin: 0;
      font-family: "MAN Europe", sans-serif;
    }
  }

  .windchill-dialog-download-btn {
    // stylelint-disable declaration-no-important
    position: absolute;
    bottom: 20px;
    font-weight: 400;
    font-family: "MAN Europe", sans-serif !important;
    color: $mi-anthracite-500 !important;
    text-decoration: underline;
    text-align: left;
  }
</style>
