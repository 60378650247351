import { reactive } from 'vue'

export const usePagination = ({
  currentPage = 1,
  totalPages = 1,
  maxNavPage = 5,
  pageSize = 10,
  totalElements = 0
} = {}) => {
  const pagination = reactive({
    currentPage,
    totalPages,
    pageSize,
    maxNavPage,
    totalElements
  })

  const handleChange = page => {
    if (page >= 1 && page <= pagination.totalPages) {
      pagination.currentPage = page
    }
    else {
      throw new Error('Invalid page')
    }
  }

  return { pagination, handleChange }
}
