<template>
  <div class="row q-col-gutter-md">
    <div
      v-for="({ name, icon, title, description }) in CONFIGURATION_TYPES"
      :key="name"
      class="col-6 flex"
    >
      <mi-card
        :flat="false"
        :class="{ 'configuration-type-card--active': selectedType === name }"
        class="configuration-type-card cursor-pointer"
        tabindex="0"
        @click="$emit('update:selected-type', name)"
      >
        <mi-card-section>
          <h6 class="flex items-center q-my-none">
            <mi-icon class="q-mr-sm" size="1.5rem" :name="icon"></mi-icon>
            {{ title }}
          </h6>
        </mi-card-section>

        <mi-card-section class="configuration-type-card__description q-pt-none">
          {{ description }}
        </mi-card-section>
      </mi-card>
    </div>
  </div>

  <!-- Stepper navigation -->
  <mi-stepper-navigation>
    <mi-btn @click="$emit('click:continue')"> Continue </mi-btn>
  </mi-stepper-navigation>
</template>

<script>
  import { CONFIGURATION_TYPES } from '@/constants'

  export default {
    name: 'NewConfigurationDialogSelectType',
    props: {
      selectedType: {
        type: String,
        required: true
      }
    },
    emits: ['click:continue', 'update:selected-type'],
    data: () => ({ CONFIGURATION_TYPES })
  }
</script>

<style lang="scss" scoped>
  .configuration-type-card {
    flex-grow: 1;
    box-shadow: 0 2px 8px rgba(48, 61, 74, .25);
    transition: background-color 300ms cubic-bezier(.25, .8, .5, 1);

    &__description {
      font-size: 12px;
      line-height: 16px;
      color: $mi-anthracite-600;
    }

    &:hover,
    &--active {
      background-color: $mi-red-50;
    }

    &--active {
      box-shadow: 0 2px 8px rgba(48, 61, 74, .25), 0 0 0 3px $mi-red-400 inset;
    }
  }
</style>
