<template>
  <mi-dialog
    key="pac-windchill-dialog"
    :model-value="modelValue"
    class="windchill-dialog"
    no-backdrop-dismiss
    custom-design-dialog
    @update:model-value="emitUpdateModelValue"
  >
    <!-- Data loading spinner -->
    <mi-inner-loading class="bg-white" :showing="isLoading">
      <mi-spinner size="70px"></mi-spinner>
      <h6 class="q-mt-lg q-mb-none"> Loading data. Please wait ... </h6>
    </mi-inner-loading>

    <template v-if="!isLoading">
      <template v-if="!isSavingConfiguration && !isExportingToWindchill && !isExportSuccessful">
        <!-- Header -->
        <div class="windchill-dialog-header">
          <div>
            <h4 class="windchill-dialog-header--title q-my-sm q-px-none"> Exporting to Windchill </h4>
            <p class="windchill-dialog-header--subtitle"> Create or update an existing configuration in Windchill </p>
          </div>
          <mi-btn
            v-close-popup
            icon="close"
            icon-type
            icon-size="18px"
            fab
            flat
          ></mi-btn>
        </div>

        <!-- Initial Component -->
        <configuration-windchill-first-step
          v-if="activeComponent === initialComponent"
          @open:filter="activeComponent = filterComponent"
          @open:variant-specification="activeComponent = variantSpecificationComponent"
          @export:done="emitUpdateModelValue"
        ></configuration-windchill-first-step>

        <!-- Variant Specification -->
        <configuration-variant-specification
          v-if="activeComponent === variantSpecificationComponent"
          v-model:selected-container="selectedContainer"
          :option-sets="optionSets"
          :config-specs="configSpecs"
          :containers="containers"
          @status:windchill-export="handleWindchillExportStatus"
          @back:windchill="activeComponent = initialComponent"
          @async:clear-poll="handleClearPoll"
        >
        </configuration-variant-specification>

        <!-- Filter -->
        <configuration-windchill-filter
          v-if="activeComponent === filterComponent"
          :option-sets="optionSets"
          @status:windchill-export="handleWindchillExportStatus"
          @back:windchill="activeComponent = initialComponent"
          @async:clear-poll="handleClearPoll"
        ></configuration-windchill-filter>
      </template>

      <!-- Saving configuration loading -->
      <configuration-windchill-status
        v-else-if="isSavingConfiguration"
        ref="statusSaving"
        loading-label="Saving file"
      >
      </configuration-windchill-status>

      <!-- Exporting to windchill loading -->
      <configuration-windchill-status
        v-else-if="isExportingToWindchill"
        ref="statusExporting"
        loading-label="Exporting to windchill"
      >
      </configuration-windchill-status>

      <!-- Export success -->
      <configuration-windchill-success
        v-else-if="isExportSuccessful"
        @done:windchill="handleWindchillDone"
      >
      </configuration-windchill-success>
    </template>
  </mi-dialog>
</template>

<script>
  import {
    getWindchillConfigSpecs,
    getWindchillContainers,
    getWindchillOptionSets
  } from '@/api'
  import ConfigurationWindchillFirstStep
    from '@/components/pac/configuration/windchill-interface/ConfigurationWindchillInitial.vue'
  import ConfigurationVariantSpecification
    from '@/components/pac/configuration/windchill-interface/ConfigurationVariantSpecification.vue'
  import ConfigurationWindchillFilter
    from '@/components/pac/configuration/windchill-interface/ConfigurationWindchillFilter.vue'
  import ConfigurationWindchillStatus
    from '@/components/pac/configuration/windchill-interface/ConfigurationWindchillStatus.vue'
  import ConfigurationWindchillSuccess
    from '@/components/pac/configuration/windchill-interface/ConfigurationWindchillSuccess.vue'

  export default {
    name: 'ConfigurationWindchillDialog',
    components: {
      ConfigurationWindchillFilter,
      ConfigurationVariantSpecification,
      ConfigurationWindchillFirstStep,
      ConfigurationWindchillStatus,
      ConfigurationWindchillSuccess
    },
    props: {
      modelValue: {
        type: Boolean,
        required: true
      }
    },

    emits: ['update:model-value'],
    data() {
      return {
        // windchill reference data
        isLoading: true,
        configSpecs: [],
        containers: [],
        optionSets: [],
        // type of windchill export
        activeComponent: 'initial',
        initialComponent: 'initial',
        filterComponent: 'filterComponent',
        variantSpecificationComponent: 'variantSpecificationComponent',
        // export status
        isSavingConfiguration: false,
        isExportingToWindchill: false,
        isExportSuccessful: false,
        clearPoll: null,
        selectedContainer: ''
      }
    },
    beforeUnmount() {
      this.clearPoll?.()
    },
    async created() {
      const setConfigSpecs = async () => {
        this.configSpecs = await getWindchillConfigSpecs()
      }
      const setContainers = async () => {
        this.containers = await getWindchillContainers()
      }
      const setOptionSets = async () => {
        this.optionSets = await getWindchillOptionSets()
      }
      await Promise.allSettled([
        setConfigSpecs(),
        setContainers(),
        setOptionSets()
      // eslint-disable-next-line no-unused-vars
      ]).then(resPromises => {
        this.isLoading = false
      })
    },
    methods: {
      emitUpdateModelValue(value = false) {
        this.$emit('update:model-value', value)
      },
      handleWindchillDone() {
        this.isExportSuccessful = false
        this.emitUpdateModelValue(false)
      },
      handleClearPoll({ clearPoll }) {
        this.clearPoll = clearPoll
      },
      handleWindchillExportStatus(status = {}) {
        if (this.isSavingConfiguration && !this.isExportingToWindchill
          && status.isExportingToWindchill) {
          // Saving -> Exporting
          this.$refs.statusSaving?.finishLoading?.()
        }
        else if (this.isExportingToWindchill && !this.isExportSuccessful
          && status.isExportSuccessful) {
          // Exporting -> Success
          this.$refs.statusExporting?.finishLoading?.()
        }

        setTimeout(() => {
          this.isSavingConfiguration = status.isSavingConfiguration || false
          this.isExportingToWindchill = status.isExportingToWindchill || false
          this.isExportSuccessful = status.isExportSuccessful || false
        }, 500)
      }
    }
  }
</script>

<style lang="scss">
  .windchill-dialog {
    .q-dialog__inner > .mi-card {
      width: 924px;
      max-width: 924px;
      min-width: 580px;
      height: 700px;
      min-height: 700px;
      max-height: 700px;
    }
  }

  @media (max-width: $mi-responsive-large-width) {
    .windchill-dialog {
      .q-dialog__inner > .mi-card {
        width: 683px;
        max-width: 683px;
      }
    }

    .windchill-dialog-header {
      width: 683px;
      max-width: 683px;
    }
  }

  .windchill-dialog-header {
    background-color: $mi-anthracite-50;
    height: 111px;
    width: 924px;
    margin: 0;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: fixed;
    z-index: 1;

    &--title {
      font-size: 1.75rem;
    }

    &--subtitle {
      font-size: .875rem;
      color: $mi-anthracite-800;
    }
  }
</style>
