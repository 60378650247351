import { reactive } from 'vue'

export const useTable = columns => {
  const table = reactive({
    columns,
    rows: [],
    loading: false,
    sort: {}
  })

  const fetchTableData = async (fetchData, elementsField = 'elements') => {
    table.loading = true
    try {
      const data = await fetchData()
      table.rows = data[elementsField] || data || []
      return data
    }
    catch (err) {
      throw new Error('Something went wrong trying to fetch data to populate the table')
    }
    finally {
      table.loading = false
    }
  }

  const updateTableRow = (key, keyValue, element) => {
    const currentIndex = table.rows.findIndex(row => row[key] === keyValue)
    table.rows.splice(currentIndex, 1, element)
  }

  const onChangeSortTableDirection = key => {
    const sortDirection = col => (col === 'DESC' ? 'ASC' : 'DESC')
    table.columns = table.columns.map(column => {
      if (column.name === key) {
        const direction = sortDirection(column.sortDirection)
        table.sort = { sortBy: key, sortDirection: direction }
        return { ...column, sortDirection: direction }
      }
      return { ...column, sortDirection: 'ASC' }
    })
  }

  const onChangeSortTableColumnVisibility = (key, state) => {
    table.columns = table.columns.map(column => {
      if (column.name === key) {
        return { ...column, showSortElement: state }
      }
      return { ...column, showSortElement: false }
    })
  }

  return {
    table,
    callbacks: {
      fetchTableData,
      updateTableRow,
      onChangeSortTableDirection,
      onChangeSortTableColumnVisibility
    }
  }
}
