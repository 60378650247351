<template>
  <mi-list-item-section class="option-selected-choices" side>
    <div class="row justify-end">
      <!-- Selected choices list -->
      <div v-for="(items, type) in choices" :key="type" class="flex no-wrap">
        <template v-for="(choice, choiceIdx) in items" :key="choice.id">
          <mi-chip
            v-if="choiceIdx === 0 && isInvalidOption(type)"
            :color="type"
            :label="`${ choice.id2 } - ${ choice.name }`"
            :title="`${ choice.id2 } - ${ choice.name }`"
            class="option-selected-choices__chip"
            outline
            removable
            @remove="removeChoice(optionId, choice.id, choice.id2)"
          ></mi-chip>

          <mi-chip v-if="choiceIdx === 1" :color="type" outline> +{{ items.length - 1 }} </mi-chip>
        </template>
      </div>
    </div>
  </mi-list-item-section>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import notify from '@/utils/notify'

  const { mapActions, mapState } = createNamespacedHelpers('pac')

  export default {
    name: 'ConfigurationOptionsItemSelectedChoices',
    props: {
      optionId: {
        type: String,
        required: true
      },
      isAllOptions: {
        type: Boolean,
        required: true,
        default: true
      }
    },
    computed: {
      ...mapState({
        choices({ possibleCombinationChoicesIds, selectedChoices } = {}) {
          const choices = {
            positive: [],
            negative: [],
            primary: []
          }
          const validChoicesIds = possibleCombinationChoicesIds.valid?.get(this.optionId) || []
          const invalidChoicesIds = possibleCombinationChoicesIds.invalid?.get(this.optionId) || []

          selectedChoices.get(this.optionId)?.forEach(choice => {
            if (validChoicesIds.includes(choice.id) && this?.errorCode !== 'FIXED_ASSUMPTIONS_INVALID') {
              choices.positive.push(choice)
            }
            else if (invalidChoicesIds.includes(choice.id) && this?.errorCode !== 'FIXED_ASSUMPTIONS_INVALID') {
              choices.negative.push(choice)
            }
            else {
              choices.primary.push(choice)
            }
          })

          return choices
        },
        errorCode: 'errorCode'
      })
    },
    methods: {
      ...mapActions(['removeSelectedChoiceFromConfiguration']),

      isInvalidOption(type) {
        return this.isAllOptions ? true : type !== 'positive'
      },
      removeChoice(optionId, choiceId, choiceName) {
        this.removeSelectedChoiceFromConfiguration({ optionId, choiceId })

        notify({
          title: 'Unselected',
          content: `Invalid choice "${ choiceName || '' }" was successfully unselected`,
          type: 'positive'
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .option-selected-choices.mi-list-item-section.q-item__section--side {
    margin-right: -.25rem;
    padding-right: 0;
  }

  .option-selected-choices__chip {
    max-width: 240px;
  }
</style>
