<template>
  <div
    v-show="!options.areLoading"
    class="configuration-options-header row justify-between items-center bg-white q-pb-sm"
  >
    <!-- Options total size -->
    <div class="col-auto">
      <strong class="text-body3 text-weight-bold">
        <template v-if="activeFilterName"> {{ filteredOptions.length }} / </template>
        {{ options.totalSize }} options shown
      </strong>
    </div>

    <div v-show="isAllOptions" class="col-auto flex items-center">
      <!-- Options filter -->
      <options-filter v-model="activeFilterName" :filter-types="FILTER_TYPES"></options-filter>

      <!-- Fill up configuration menu -->
      <fill-up-menu class="q-ml-xs"></fill-up-menu>
    </div>
  </div>

  <!-- Options -->
  <mi-infinite-scroll
    v-if="displayedOptions.length && !options.areLoading"
    ref="miInfiniteScroll"
    class="mi-infinite-scroll"
    @load="loadMoreOptions"
  >
    <option-item
      v-for="option in displayedOptions"
      :ref="insertIntoOptionsRef"
      :key="option.id"
      :option="option"
      :is-all-options="isAllOptions"
      @target-loaded-choices="targetLoadedChoices"
    ></option-item>

    <template #loading>
      <div class="loading-spinner">
        <div class="row justify-center q-mt-lg q-mb-md">
          <mi-spinner size="24px"></mi-spinner>
          <h6 class="q-ml-md q-my-none"> Loading more options ... </h6>
        </div>
      </div>
    </template>
  </mi-infinite-scroll>

  <!-- No found caption -->
  <div
    v-show="!displayedOptions.length"
    class="col-grow column justify-center items-center text-primary no-options"
  >
    <div v-show="isAllOptions" class="no-options__all">
      <mi-icon name="warning-circle" size="26px"></mi-icon>
      <h6 class="q-mb-none q-mt-sm"> No options found </h6>
    </div>
    <div v-show="!isAllOptions" class="no-options__invalid">
      <img src="@/assets/images/pac/check-circle.svg" alt="no invalid options" width="48" height="48" />
      <span class="q-mb-none q-mt-sm"> There are no invalid choices found.</span>
      <span class="q-mb-none q-mt-sm"> Validate to check if any new one shows up. </span>
    </div>
  </div>

  <!-- Loading state -->
  <mi-inner-loading :showing="options.areLoading" class="bg-white">
    <div class="loading-spinner">
      <mi-spinner size="70px"></mi-spinner>
      <h6 class="q-mt-lg q-mb-none"> Loading options. Please wait ... </h6>
    </div>
  </mi-inner-loading>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import {
    SET_ARE_INVALID_OPTIONS_LOADING,
    SET_ARE_OPTIONS_LOADING,
    SET_CLEAR_CONFIGURATION_OPTIONS_QUERY
  } from '@/store/modules/pac/mutationTypes'

  import FillUpMenu from '@/components/pac/configuration/ConfigurationFillUpMenu.vue'
  import { PAC_CONFIGS_TABS } from '@/constants'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import {
    PAC_FILTERED_CONFIGURATION_OPTIONS,
    PAC_OPTIONS_INFINITE_SCROLL,
    PAC_INVALID_CHOICES_TAB_OPENED
  } from '@/utils/analytics/constants'
  import OptionItem from '@/components/pac/configuration/options/item/ConfigurationOptionsItem.vue'
  import sleep from '@/utils/sleep'
  import notify from '@/utils/notify'
  import OptionsFilter from './ConfigurationOptionsFilter.vue'

  const { mapGetters, mapMutations, mapState } = createNamespacedHelpers('pac')
  // Changed from 40->2000 = Workaround discussed with Tiago, to prevent the user to wait a lot of time
  // to get to the option if it is not rendered yet
  const OPTIONS_TO_LOAD_AMOUNT = 2000

  export default {
    name: 'ConfigurationOptionsType',
    components: {
      OptionItem,
      FillUpMenu,
      OptionsFilter
    },
    props: {
      options: {
        type: Object,
        required: false,
        default: undefined
      },
      targetInvalidChoice: {
        type: Object,
        required: false,
        default: undefined
      },
      activeConfig: {
        type: String,
        required: false,
        default: PAC_CONFIGS_TABS.ALL.value
      }
    },
    data() {
      return {
        PAC_CONFIGS_TABS,
        FILTER_TYPES: [
          { name: 'withoutSelection', title: 'Without a selection', filterFn: this.filterOptionsWithoutSelection },
          { name: 'withLocks', title: 'With locks', filterFn: this.filterOptionsWithLocks }
        ],
        activeFilterName: '',
        configurationWrapperEl: null,
        displayedOptions: [],
        filteredOptions: [],
        optionItemsRefs: [],
        targetInvalidChoiceFlow: false,
        activeChoice: null
      }
    },
    computed: {
      ...mapState([
        'lockedChoicesIds',
        'selectedChoices',
        'clearConfigurationOptionsQuery'
      ]),
      ...mapGetters(['optionsAreLoading']),

      isAllOptions() {
        if (this.activeConfig === PAC_CONFIGS_TABS.INVALID.value) {
          recordAnalytics(PAC_INVALID_CHOICES_TAB_OPENED)
        }
        return this.activeConfig === PAC_CONFIGS_TABS.ALL.value
      }
    },
    watch: {
      targetInvalidChoice: {
        async handler(choice = {}) {
          await this.clearFilters()
          this.$nextTick(async () => {
            this.activeChoice = choice
            await this.selectInvalidChoice(choice, true)
          })
        }
      }
    },
    mounted() {
      this.$watch(
        () => [this.activeFilterName, JSON.stringify(this.options.items)],
        this.triggerOptionsFiltering
      )
      this.triggerOptionsFiltering()
    },
    methods: {
      ...mapMutations({
        SET_ARE_OPTIONS_LOADING,
        SET_ARE_INVALID_OPTIONS_LOADING,
        SET_CLEAR_CONFIGURATION_OPTIONS_QUERY
      }),

      targetLoadedChoices(loaded) {
        if (!(loaded && this.activeChoice && this.targetInvalidChoiceFlow)) return
        const elem = document.querySelector(`[choice-id="${ this.activeChoice }"]`)
        if (!elem) return
        elem.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        })

        this.targetInvalidChoiceFlow = false
      },
      insertIntoOptionsRef(el = null) {
        if (el == null) {
          return
        }

        const idx = this.optionItemsRefs.findIndex(ref => ref.option?.id2 === el.option?.id2)
        if (idx < 0) {
          this.optionItemsRefs.push(el)
        }
        else {
          this.optionItemsRefs[idx] = el
        }
      },
      async clearFilters() {
        if (this.activeFilterName.length) {
          this.activeFilterName = ''
        }

        if (!this.optionsAreLoading) {
          this.SET_CLEAR_CONFIGURATION_OPTIONS_QUERY(true)

          await new Promise(resolve => setTimeout(resolve, 1000))

          while (this.clearConfigurationOptionsQuery) {
            // eslint-disable-next-line no-await-in-loop
            await sleep(100)
          }
        }
      },
      scrollToElement(element) {
        // Define a helper function to scroll an element into view
        element.$el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start'
        })
      },
      async fetchRemainingOptions(component) {
        if (component.displayedOptions.length >= component.filteredOptions.length) {
          return // No more options to fetch
        }

        while (
          component.displayedOptions.length < component.filteredOptions.length
          && !component.optionItemsRefs.some(ref => ref.option.id2 === component.optionId2)
        ) {
          component.$refs.miInfiniteScroll.trigger()
          // eslint-disable-next-line no-await-in-loop
          await sleep(600)
        }
      },
      handleOptionNotFound(component, allowRetry) {
        // Define a helper function to handle the case when the option is not found
        if (allowRetry) {
          this.$nextTick(async () => {
            await this.selectInvalidChoice(
              {
                choiceId: component.choiceId,
                choiceId2: component.choiceId2,
                optionId: component.optionId,
                optionId2: component.optionId2
              },
              false
            )
          })
        }
      },
      async selectInvalidChoice(
        { choiceId = '', choiceId2 = '', optionId = '', optionId2 = '' } = {},
        allowRetry = false
      ) {
        const targetOption = this.optionItemsRefs.find(ref => ref.option.id2 === optionId2)

        if (targetOption) {
          this.targetInvalidChoiceFlow = true
          this.activeChoice = choiceId2

          if (!targetOption.isOptionExpanded) {
            await this.$nextTick()
            this.scrollToElement(targetOption)
            await targetOption.getOptionChoices(true)
          }
          else {
            this.targetLoadedChoices(true)
          }
        }
        else if (this.options.items.some(option => option.id2 === optionId2)) {
          if (this.displayedOptions.length === this.filteredOptions.length) {
            await sleep(600)
          }
          await this.fetchRemainingOptions(this)
          this.handleOptionNotFound({ choiceId, choiceId2, optionId, optionId2 }, allowRetry)
        }
        else {
          notify({ content: `Option "${ optionId2 }" was not found in the list`, type: 'negative' })
        }
      },
      filterOptionsWithLocks() {
        this.filteredOptions = [...Object.freeze(this.options.items.filter(
          option => this.lockedChoicesIds.has(option.id)
        ))]
      },
      filterOptionsWithoutSelection() {
        this.filteredOptions = [...Object.freeze(this.options.items.filter(
          option => !this.selectedChoices.has(option.id)
        ))]
      },
      loadMoreOptions(index, done) {
        const optionsToLoad = this.filteredOptions
          .slice(index * OPTIONS_TO_LOAD_AMOUNT, (index + 1) * OPTIONS_TO_LOAD_AMOUNT)

        if (optionsToLoad.length > 0) {
          setTimeout(() => {
            this.displayedOptions.push(...Object.freeze(optionsToLoad))
            done()

            recordAnalytics(PAC_OPTIONS_INFINITE_SCROLL, { amount: this.displayedOptions.length })
          }, 500)
        }
        else {
          done(true)
        }
      },
      resetDisplayedOptions() {
        this.displayedOptions = [...Object.freeze(this.filteredOptions.slice(0, OPTIONS_TO_LOAD_AMOUNT))]

        this.$nextTick(() => {
          this.$refs.miInfiniteScroll?.reset?.()
          this.optionItemsRefs = []
        })
      },
      triggerOptionsFiltering() {
        let filterFn = () => {
          this.filteredOptions = [...this.options.items]
        }

        if (this.activeFilterName) {
          ({ filterFn } = this.FILTER_TYPES.find(({ name = '' } = {}) => this.activeFilterName === name) || {})
        }

        if (typeof filterFn === 'function') {
          this.isAllOptions ? this.SET_ARE_OPTIONS_LOADING(true) : this.SET_ARE_INVALID_OPTIONS_LOADING(true)

          setTimeout(() => {
            filterFn()
            this.resetDisplayedOptions()
            this.isAllOptions ? this.SET_ARE_OPTIONS_LOADING() : this.SET_ARE_INVALID_OPTIONS_LOADING()
          }, 500)

          // Analytics
          recordAnalytics(
            PAC_FILTERED_CONFIGURATION_OPTIONS,
            { type: this.activeFilterName }
          )
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .configuration-options-header {
    position: sticky;
    top: 0;
    padding-top: 1.25rem;
    margin-top: -1.25rem;
    z-index: 2;
  }

  .mi-infinite-scroll {
    overflow: auto;
    margin-top: .75em;
    height: 65vh;
    overflow-y: scroll;
  }

  .mi-infinite-scroll::-webkit-scrollbar {
    width: .1em;
  }

  .mi-infinite-scroll::-webkit-scrollbar-thumb {
    background-color: #ffffff;
  }

  .loading-spinner {
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .no-options {
    height: 40vh;
    display: flex;
    padding: 40px 16px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;

    &__all {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__invalid {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $mi-anthracite-800;
      text-align: center;
      font-family: $mi-typography-font-family;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
    }
  }
</style>
